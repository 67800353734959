import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Session from './components/Session';
import Options from "./components/Options";
import AuthService from './services/AuthService';
import "./App.css";
import "./components/Themes.css"
import "./components/Common.css"

function App() {
  const dataEndpoint = process.env.REACT_APP_DATASERVICE_HOST;
  const setlist_redirect = process.env.REACT_APP_SETLIST_REDIRECT;
  const dataloader_redirect = process.env.REACT_APP_DATALOADER_REDIRECT;
  const [username, setUsername] = useState("nouser");
  const [userProfile, setUserProfile] = useState({
    "theme": "light",
    "layout": "default",
    "show_chord_notation": false,
    "show_lyric_tab": true,
    "show_annotation": false,
    "plain_sheet": false
  });

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const getProfile = async () => {
      const data = await AuthService.getProfile(dataEndpoint);
      setUserProfile(data.profile)
      setUsername(data.username)
    };
    const checkAuth = async () => {
      const authStatus = await AuthService.isAuthenticated(dataEndpoint);
      setIsAuthenticated(authStatus);
      if (authStatus) {
        getProfile();
      }
    };
    checkAuth();
  }, [dataEndpoint]);

  const handleUpdateProfile = async (key, value) => {
    const toggledKeys = ["show_chord_notation", "show_lyric_notation", "show_pins", "show_annotations"];
    const setProfile = async (profile) => {
      await AuthService.setProfile(dataEndpoint, profile);
    };

    let updatedProfile;

    if (key === "theme") {
      updatedProfile = { ...userProfile, theme: userProfile.theme === "light" ? "dark" : "light" };
    } else if (toggledKeys.includes(key)) {
      updatedProfile = { ...userProfile, [key]: !userProfile[key] };
    }

    if (updatedProfile) {
      await setUserProfile(updatedProfile);
      await setProfile(updatedProfile);
    }
  };

  const handleUpdateDisplay = async (chordNotation, lyricNotation, pins, annotations) => {
    const setProfile = async (profile) => {
      await AuthService.setProfile(dataEndpoint, profile);
    };

    let updatedProfile;
    console.log(`called ${pins}`)
    updatedProfile = { ...userProfile, ["show_chord_notation"]: chordNotation, ["show_lyric_notation"]: lyricNotation, ["show_pins"]: pins,["show_annotations"]: annotations };

      await setUserProfile(updatedProfile);
      await setProfile(updatedProfile);
  };

  const handleLogin = async (username, password) => {
    const success = await AuthService.login(dataEndpoint, username, password);
    if (success) setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    await AuthService.logout(dataEndpoint); // Await logout
    setIsAuthenticated(false);
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
      <Router>
        <Routes>
          <Route path="/" element={!isAuthenticated ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={isAuthenticated ? <Dashboard onLogout={handleLogout} setlist_redirect={setlist_redirect} dataloader_redirect={dataloader_redirect} username={username} userProfile={userProfile} handleUpdateProfile={handleUpdateProfile} handleUpdateDisplay={handleUpdateDisplay}/> : <Navigate to="/" />} />
          <Route path="/sessions" element={isAuthenticated ? <Session userProfile={userProfile} /> : <Navigate to="/" />} />
          <Route path="/options" element={isAuthenticated ? <Options userProfile={userProfile} handleUpdateProfile={handleUpdateProfile} handleUpdateDisplay={handleUpdateDisplay} username={username}/> : <Navigate to="/" />} />
        </Routes>
      </Router>
  );
}

export default App;

